<template>
    <div class="container">
        <div class="create-title">LOGO设置</div>
        <div class="create-container">
            
            <div class="form-item">
                <div class="key">LOGO图片</div>
                <div class="val upload">
                    <el-upload
                        drag
                        class="logo-uploader"
                        :action="FILE_UPLOAD_END_POINT"
                        accept="image/png, image/jpeg, image/jpg"
                        :show-file-list="false"
                        :before-upload='beforeUpload'
                        :on-change='handleUploadChange'
                        :on-success="handleUploadSuccess"
                    >
                        <div v-if="imageUrl" class="display">
                            <img :src="imageUrl"/>
                            <div class="change">更换</div>
                        </div>
                        <div v-else class="empty">
                            <i class="el-icon-plus"/>
                            <span>选择文件</span>
                        </div>
                    </el-upload>
                    <div class="tip">
                        <p>文件格式：.jpg、.png或.jpeg</p>
                        <p>图片大小：不超过2M</p>
                        <p>图片尺寸：推荐宽度在240px左右</p>
                    </div>
                </div>
            </div>
            <div class="btn">
                <el-button size="small" type="primary" @click="reload">确认更换</el-button>
                <el-button type="primary" plain size="small" @click="reset">重置</el-button>
            </div>

            <div class="form-item" v-if="miniProgramCode">
                <div class="key">小程序二维码</div>
                <img :src="miniProgramCode" alt="" class="value"/>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '../../core';


export default {
    data(){
        return {
            FILE_UPLOAD_END_POINT: Core.Const.NET.FILE_UPLOAD_END_POINT,
            IMG_URL_PREFIX: Core.Const.NET.FILE_URL_PREFIX2,
            imageUrl: '',

            orgId: '',

            miniProgramCode: '',
        }
    },
    mounted() {
        this.getLogo()
    },
    methods: {
        // 上传logo
        beforeUpload(file){  //上传logo 校验图片
            const isJPG = ['image/jpeg','image/jpg', 'image/png'].includes(file.type); 
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.warning('请上传，JPG、PNG或JPEG格式的图片');
            }
            if (!isLt2M) {
                this.$message.warning('上传图像大小不要超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        handleUploadChange(file){ // 上传logo 状态提醒
            console.log('handleUploadChange file:', file )
            switch (file.status) {
                case 'success':
                    this.$message.success('上传成功');
                    break;
                case 'error':
                    this.$message.error('上传失败');
                    break;
            }
        },
        handleUploadSuccess(res, file) { // 上传logo 成功
            console.log('handleUploadSuccess res:', res, 'file', file)
            console.log('res.data.name:',res.data.name)
            this.imageUrl = res.data.name ? this.IMG_URL_PREFIX + res.data.name : ''
            console.log('this.imageUrl:', this.imageUrl)
            Core.Api.Org.saveOrgConfig(this.imageUrl).then(function(){
                this.getLogo()
            }).catch(err => {
                console.log('error:',err)
            })
        },
        // 获取logo
        getLogo(){
            Core.Api.Org.getOrgConfigDetail().then(res => {
                this.imageUrl = res.detail.logo
                this.orgId = res.detail.org_id
                // 获取二维码
                this.miniProgramCode = Core.Const.NET.CLIENT_URL_POINT + '/client/1/wx/mini-program-code?token=' + Core.Data.getToken() + '&org_id=' + this.orgId;
                console.log('this.miniProgramCode:', this.miniProgramCode)
            }).catch(err => {
                console.log('error:',err)
            })
        },
        // 更换logo
        reload(){
            if(this.imageUrl){
                Core.Data.setLogoUrl(this.imageUrl)
                location.reload();
            } else{
                this.$message.warning('请选择图片')
            }
        },
        // 重置logo
        reset(){
            this.imageUrl = ''
            Core.Data.setLogoUrl(this.imageUrl)
            Core.Api.Org.saveOrgConfig(this.imageUrl).then(() => {
                location.reload();
            }).catch(err => {
                console.log('error:',err)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    padding: 0 30px 40px;
    .create-title {
        height: 62px;
        line-height: 62px;
        font-size: 20px;
        font-weight: 500;
        color: #323538;
    }
    .create-container {
        width: 100%;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #E1E7EC;
        overflow: hidden;
        box-sizing: border-box;
        padding: 36px 60px;
        .form-item {
            display: flex;
            margin-bottom: 20px;
            .key {
                color: #323538;
                width: 90px;
            }
            // .val {
            //     width: calc(100% - 90px);
            // }
            .upload {
                display: flex;
            }
            .tip {
                margin-left: 20px;
                margin-right: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                p {
                    margin-bottom: 8px;
                }
            }
            .logo-uploader {
                .empty {
                    @include flex(column, center, center);
                    width: 100%;
                    height: 100%;
                    color: #666666;
                    background: #FAFBFC;
                    i.el-icon-plus {
                        font-size: 20px;
                    }
                    > span {
                        font-size: 12px;
                    }
                }
                .display {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 1;
                    .change {
                        position: absolute;
                        left: 0;
                        right: 0;
                        // bottom: 0;
                        // top: 0;
                        margin-left: auto;
                        margin-right: auto;
                        width: 50%;
                        height: 50%;
                        color: #fff;
                        z-index: 2;
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }    
        }
        .btn{
            margin-bottom: 40px;
        }
        &:last-child{
            margin-bottom: 0px;
            .value{
                width: 100px;
                height: 100px;
            }
        }
    }
}

</style>

<style lang="scss">
.logo-uploader {
    .el-upload {
        .el-upload-dragger {
            width: 120px;
            height: 120px;
        }
    }
}
</style>